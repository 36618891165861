import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import { Link } from "gatsby";
const languageIds = require("../dic/dic.json");

const BlogDestacados = ({ posts, langPage, idCat }) => {
  const postDestacados = posts;
  const lang = langPage;
  const idCategoria = idCat;
  let cat = null;
  let nameCat = null;
  return (
        <>
            <div className="row">
                {postDestacados !== null
                  ? postDestacados.map(post => {
                    let mainImage = null;

                    if (post.featuredImage !== null &&
                            post.featuredImage.node !== null &&
                            post.featuredImage.node.localFile !== null) {
                      mainImage = post.featuredImage.node.localFile.childImageSharp.fluid;
                      mainImage.sizes = "(max-width: 540px) 100px,(min-width: 768px) and (max-width: 1200px) 400px, 600px";
                    }
                    return (
                            <div key={post.databaseId} className="post-wrapper col-12 col-md-6 col-lg-4">

                                <article className="post-item">
                                    {post.categories.nodes.map(categoria => {
                                      if (categoria.databaseId === idCategoria) {
                                        cat = categoria.slug;
                                        nameCat = categoria.name;
                                      } else {
                                        // if(categoria.databaseId==102 || categoria.databaseId==99){
                                        //   main_cat = categoria.slug // not used
                                        // }
                                      }
                                      return true;
                                    })}
                                    <div className="header">
                                        <button className="overlay" onClick={() => { location.href = `/${langPage}/blog/${post.slug}` }}>
                                            <span>Leer Más</span>
                                        </button>
                                        {mainImage !== null
                                          ? (
                                            <Img itemProp="image"
                                                fluid={mainImage}
                                                className="lazy header"
                                                alt={post.featuredImage.node.altText}
                                            />
                                            )
                                          : null}
                                    </div>
                                    <div className="inner">
                                        <aside className="infopost">
                                            <div className="metadata">
                                                {post.date}
                                            </div>
                                        </aside>
                                        <h3 className="title"><Link to={`/${lang}/blog/${post.slug}`}>{post.title}</Link></h3>
                                    </div>
                                </article>
                            </div>

                    );
                  })
                  : null
                }
            </div>

            <div className="ver-todos">
                {postDestacados.length > 0
                  ? (
                        <Link to={`/${lang}/blog/${cat !== null ? cat : "#"}`} className="link-ver-todos">
                            {languageIds[lang].ver_posts_destacados} {nameCat}
                        </Link>
                    )
                  : null}
            </div>

        </>
  );
};
BlogDestacados.propTypes = {
  posts: PropTypes.object.isRequired
};
export default BlogDestacados;
