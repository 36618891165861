import React, { Component } from "react";
import _ from "lodash";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Img from "gatsby-image";
import SEO from "../components/seo";
import BlogDestacados from "../components/blog-destacados.js";

import Breadcrumb from "../components/breadcrumb.js";
const { htmlToText } = require("html-to-text");
var dictionary = require("../dic/dic.json");

class BlogNew extends Component {
  constructor (props) {
    super(props);
    this.state = { catAutonomos: null, catCasosExitos: null, catFacturacion: null, catFinanzas: null, catFiscalidad: null, catMarketing: null, catModelos: null, catProductividad: null, catPymes: null, catStartup: null, destacadosAutonomos: [], destacadosCasosExitos: [], destacadosFacturacion: [], destacadosFinanzas: [], destacadosFiscalidad: [], destacadosMarketing: [], destacadosModelos: [], destacadosProductividad: [], destacadosPymes: [], destacadosStartup: [] };
  }

  componentDidMount () {
    //console.log("idioma " + this.props.pageContext.idioma);
    if (this.props.pageContext.idioma === "es") {
      const myForm = document.getElementById("buscador-new");

      myForm.addEventListener("submit", function (event) {
        event.preventDefault();
        window.location.replace("https://getbillage.com/es/buscador-blog?search=" + myForm.search.value);
      });
    } else {
      const myForm = document.getElementById("buscador-new");
      myForm.addEventListener("submit", function (event) {
        event.preventDefault();
        window.location.replace("https://getbillage.com/en/blog-search?search=" + myForm.search.value);
      });
    }
    var DestacadosAutonomos = this.state.destacadosAutonomos;
    var DestacadosCasosExitos = this.state.destacadosCasosExitos;
    var DestacadosFacturacion = this.state.destacadosFacturacion;
    var DestacadosFinanzas = this.state.destacadosFinanzas;
    var DestacadosFiscalidad = this.state.destacadosFiscalidad;
    var DestacadosMarketing = this.state.destacadosMarketing;
    var DestacadosModelos = this.state.destacadosModelos;
    var DestacadosProductividad = this.state.destacadosProductividad;
    var DestacadosPymes = this.state.destacadosPymes;
    var DestacadosStartup = this.state.destacadosStartup;

    var CatAutonomos = this.state.catAutonomos;
    var CatCasosExitos = this.state.catCasosExitos;
    var CatFacturacion = this.state.catFacturacion;
    var CatFinanzas = this.state.catFinanzas;
    var CatFiscalidad = this.state.catFiscalidad;
    var CatMarketing = this.state.catMarketing;
    var CatModelos = this.state.catModelos;
    var CatProductividad = this.state.catProductividad;
    var CatPymes = this.state.catPymes;
    var CatStartup = this.state.catStartup;

    const posts = this.props.data.allWpPost.nodes;

    var contadorAutonomos = 0;
    var contadorCasosExitos = 0;
    var contadorFacturacion = 0;
    var contadorFinanzas = 0;
    var contadorFiscalidad = 0;
    var contadorMarketing = 0;
    var contadorModelos = 0;
    var contadorProductividad = 0;
    var contadorPymes = 0;
    var contadorStartup = 0;

    for (var post of posts) {
      if (post.categories.nodes.find(element => element.databaseId === 9525) && contadorAutonomos < 3) {
        contadorAutonomos++;
        CatAutonomos = 209;
        DestacadosAutonomos.push(post);
      }
      if (post.categories.nodes.find(element => element.databaseId === 9552) && contadorCasosExitos < 3) {
        contadorCasosExitos++;
        CatCasosExitos = 166;
        DestacadosCasosExitos.push(post);
      }
      if (post.categories.nodes.find(element => element.databaseId === 9528) && contadorFacturacion < 3) {
        contadorFacturacion++;
        CatFacturacion = 298;
        DestacadosFacturacion.push(post);
      }
      if (post.categories.nodes.find(element => element.databaseId === 9531) && contadorFinanzas < 3) {
        contadorFinanzas++;
        CatFinanzas = 9482;
        DestacadosFinanzas.push(post);
      }
      if (post.categories.nodes.find(element => element.databaseId === 9534) && contadorFiscalidad < 3) {
        contadorFiscalidad++;
        CatFiscalidad = 9494;
        DestacadosFiscalidad.push(post);
      }
      if (post.categories.nodes.find(element => element.databaseId === 9537) && contadorMarketing < 3) {
        contadorMarketing++;
        CatMarketing = 9484;
        DestacadosMarketing.push(post);
      }
      if (post.categories.nodes.find(element => element.databaseId === 9549) && contadorModelos < 3) {
        contadorModelos++;
        CatModelos = 9487;
        DestacadosModelos.push(post);
      }
      if (post.categories.nodes.find(element => element.databaseId === 9540) && contadorProductividad < 3) {
        contadorProductividad++;
        CatProductividad = 328;
        DestacadosProductividad.push(post);
      }
      if (post.categories.nodes.find(element => element.databaseId === 9543) && contadorPymes < 3) {
        contadorPymes++;
        CatPymes = 1396;
        DestacadosPymes.push(post);
      }
      if (post.categories.nodes.find(element => element.databaseId === 9546) && contadorStartup < 3) {
        contadorStartup++;
        CatStartup = 251;
        DestacadosStartup.push(post);
      }
    }
    this.setState({ catAutonomos: CatAutonomos, catCasosExitos: CatCasosExitos, catFacturacion: CatFacturacion, catFinanzas: CatFinanzas, catFiscalidad: CatFiscalidad, catMarketing: CatMarketing, catModelos: CatModelos, catProductividad: CatProductividad, catPymes: CatPymes, catStartup: CatStartup, destacadosAutonomos: DestacadosAutonomos, destacadosCasosExitos: DestacadosCasosExitos, destacadosFacturacion: DestacadosFacturacion, destacadosFinanzas: DestacadosFinanzas, destacadosFiscalidad: DestacadosFiscalidad, destacadosMarketing: DestacadosMarketing, destacadosModelos: DestacadosModelos, destacadosProductividad: DestacadosProductividad, destacadosPymes: DestacadosPymes, destacadosStartup: DestacadosStartup });
    this.dataLayerEvents();
  }

  dataLayerEvents () {
    let items = [];
    Array.from(document.querySelectorAll(".post-list article")).forEach(post => {
      items.push({
        item_name: post.querySelector(".inner h3").textContent, // Name or ID is required.
        item_category: post.querySelector(".metadata").textContent.split("|")[0],
        index: post.dataset.index // Posición del articulo
      });
      // Event
      post.addEventListener("click", function (ev) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: "select_item",
          ecommerce: {
            items: [{
              item_name: ev.currentTarget.querySelector(".inner h3").textContent, // Name or ID is required.
              item_category: ev.currentTarget.querySelector(".metadata").textContent.split("|")[0].trim(),
              index: ev.currentTarget.dataset.index
            }]
          }
        });
      });
    });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "view_item_list",
      ecommerce: {
        items: items
      }
    });
  }

  render () {
    const posts = this.props.data.allWpPost.nodes;
    var contador_posts = 0;
    const category = this.props.data.wpCategory;
    const page = this.props.data.wpPage;
    var main_cut_slug = category.slug;
    if (category.ancestors !== null) {
      main_cut_slug = category.ancestors.nodes[0].slug;
    }
    var nameCat;
    var cat;
    const langPage = this.props.pageContext.idioma;
    var slugCategory = category.uri;
    if (slugCategory !== undefined) {
      slugCategory = slugCategory.replace("all/", "");
      slugCategory = slugCategory.replace("category/", "");
    }

    var openGraphslugPage = slugCategory
    const seo = page.seo;
    dictionary = require("../dic/dic.json");
    if (category.slug !== 'blog') {
      var breadcrumb = [];
      let categoria = {
        title: category.ancestors.nodes[0].name,
        slug: category.ancestors.nodes[0].slug
      }
      breadcrumb.push(categoria);
      categoria = {
        title: category.name,
        slug: null
      }
      breadcrumb.push(categoria);
    }

    return (
      <Layout currentPage={category.name} langPage={this.props.pageContext.idioma} clase_pagina={'page-blog'}>
        <SEO
          title={seo.title}
          description={seo.metaDesc}
          canonical={slugCategory}
          robotsNofollow={seo.metaRobotsNofollow}
          robotsNoindex={seo.metaRobotsNoindex}
          openGraphSite={seo.opengraphSiteName}
          openGraphType={seo.opengraphType}
          openGraphUrl={openGraphslugPage}
          openGraphImg={
            seo.opengraphImage !== null
              ? seo.opengraphImage.sourceUrl
              : "/images/logo-billage-default-social-share.jpg"
          }
          openGraphImgWidth={
            seo.opengraphImage !== null
              ? seo.opengraphImage.mediaDetails.width
              : 300
          }
          openGraphImgHeight={
            seo.opengraphImage !== null
              ? seo.opengraphImage.mediaDetails.height
              : 300
          }
          dctermsCreated={page.date}
          dctermsModified={page.modified}
          lang={langPage}
          twitterImg={
            seo.twitterImage !== null
              ? seo.twitterImage.sourceUrl
              : "/images/logo-billage-default-social-share.jpg"
          }
          twitterImgHeight={
            seo.twitterImage !== null ? seo.twitterImage.mediaDetails.height : 300
          }
          twitterImgWidth={
            seo.twitterImage !== null ? seo.twitterImage.mediaDetails.width : 300
          }

        />

        <Breadcrumb props={breadcrumb} isChild={category.slug === 'blog' ? "true" : "false"} isPage="false" />
        <div className="wrapper">
          <header className="header-blog-home">
            <div className="titulares">
            <h1>{category.name}</h1>
            <h2>{dictionary[this.props.pageContext.idioma]
              .new_posts}</h2>
            </div>
              {langPage === "es" ?
                <form method="GET" id="buscador-new">
                  <label htmlFor="search">Buscador: </label>
                  <input type="name" id="buscador" name="search"></input>
                  <button type="submit" name="buscar" className="searchbutton"><i className="fa fa-search" aria-hidden="true"></i></button>
                </form>
                :
                <form method="GET" id="buscador-new">
                  <label htmlFor="search">Search: </label>
                  <input type="name" id="buscador" name="search"></input>
                  <button type="submit" name="buscar" className="searchbutton"><i className="fa fa-search" aria-hidden="true"></i></button>
                </form>
                }
          </header>

          <div className="row post-list">
            {posts.map( (post,index) => {
              // var main_cat = null // not used
              var category_count = 0;
              {
                post.categories.nodes.map(categoria => {
                  category_count++;
                })
              }
              if (contador_posts < 9 && category_count > 1) {
                contador_posts++;
                var mainImage = null;
                if (post.featuredImage !== null &&
                  post.featuredImage.node !== null &&
                  post.featuredImage.node.localFile !== null) {
                  mainImage = post.featuredImage.node.localFile.childImageSharp.fluid;
                  mainImage.sizes = "(max-width: 540px) 100px,(min-width: 768px) and (max-width: 1200px) 400px, 600px";
                }

                var html = post.excerpt;
                html = html.replace("[", "")
                html = html.replace("]", "")
                var post_excerpt = htmlToText(html, {
                  ignoreHref: true,
                })
                var post_excerpt = _.truncate(post_excerpt, {
                  length: 170,
                  separator: /,?\.* +/
                })

                return (
                  <div key={post.databaseId} className="post-wrapper col-12 col-md-6 col-lg-4">
                    <article className="post-item" data-index={index + 1}>

                      {post.categories.nodes.map(categoria => {
                        if (categoria.ancestors !== null && categoria.databaseId !== 9525 && categoria.databaseId !== 9552 && categoria.databaseId !== 9528 && categoria.databaseId !== 9531 && categoria.databaseId !== 9534 && categoria.databaseId !== 9537 && categoria.databaseId !== 9549 && categoria.databaseId !== 9540 && categoria.databaseId !== 9543 && categoria.databaseId !== 9546) {
                          cat = categoria.slug
                          nameCat = categoria.name
                        } else {
                          // if(categoria.databaseId==102 || categoria.databaseId==99){
                          //   main_cat = categoria.slug // not used
                          // }
                        }
                        return true;
                      })}

                      <div className="header">
                        {/* <Link to={`/${langPage}/${main_cut_slug}/${post.slug}`} className="overlay">
                          <span>Leer Más</span>
                        </Link> */}
                        <button className="overlay" onClick={() => { location.href = `/${langPage}/${main_cut_slug}/${post.slug}`; }}>
                            <span>Leer Más</span>
                        </button>

                        {mainImage !== null
                          ? (
                          <Img itemProp="image"
                            fluid={mainImage}
                            className="lazy header"
                            alt={post.featuredImage.node.altText}
                          />
                            )
                          : null}
                      </div>
                      <div className="inner">
                        <aside className="infopost">
                          <div className="author gravatar">
                            <img
                              src={post.author.node.avatar.url}
                              alt={post.author.node.name}
                              title={post.author.node.name}
                            />
                          </div>
                          <div className="metadata">
                            {nameCat}
                            <span>|</span>
                            {post.date}
                          </div>
                        </aside>
                        <h3 className="title"><Link to={`/${langPage}/${main_cut_slug}/${post.slug}`}>{post.title}</Link></h3>
                        <Link to={`/${langPage}/${main_cut_slug}/${post.slug}`}><p className="excerpt">{post_excerpt}</p></Link>
                      </div>
                    </article>
                  </div>
                );
              }
            })}
          </div>

          <div className="destacados">
            {this.state.destacadosAutonomos.length !== 0 ? (<h2 className="title-destacados">Destacados Autonomos</h2>) : null}
            <BlogDestacados posts={this.state.destacadosAutonomos} langPage={langPage} idCat={this.state.catAutonomos} />

            {this.state.destacadosCasosExitos.length !== 0 ? (<h2 className="title-destacados">Destacados Casos de éxito</h2>) : null}
            <BlogDestacados posts={this.state.destacadosCasosExitos} langPage={langPage} idCat={this.state.catCasosExitos} />

            {this.state.destacadosFacturacion.length !== 0 ? (<h2 className="title-destacados">Destacados Facturación</h2>) : null}
            <BlogDestacados posts={this.state.destacadosFacturacion} langPage={langPage} idCat={this.state.catFacturacion} />

            {this.state.destacadosFinanzas.length !== 0 ? (<h2 className="title-destacados">Destacados Finanzas</h2>) : null}
            <BlogDestacados posts={this.state.destacadosFinanzas} langPage={langPage} idCat={this.state.catFinanzas} />

            {this.state.destacadosFiscalidad.length !== 0 ? (<h2 className="title-destacados">Destacados Fiscalidad</h2>) : null}
            <BlogDestacados posts={this.state.destacadosFiscalidad} langPage={langPage} idCat={this.state.catFiscalidad} />

            {this.state.destacadosMarketing.length !== 0 ? (<h2 className="title-destacados">Destacados Marketing</h2>) : null}
            <BlogDestacados posts={this.state.destacadosMarketing} langPage={langPage} idCat={this.state.catMarketing} />

            {this.state.destacadosModelos.length !== 0 ? (<h2 className="title-destacados">Destacados Modelos</h2>) : null}
            <BlogDestacados posts={this.state.destacadosModelos} langPage={langPage} idCat={this.state.catModelos} />

            {this.state.destacadosProductividad.length !== 0 ? (<h2 className="title-destacados">Destacados Productividad</h2>) : null}
            <BlogDestacados posts={this.state.destacadosProductividad} langPage={langPage} idCat={this.state.catProductividad} />

            {this.state.destacadosPymes.length !== 0 ? (<h2 className="title-destacados">Destacados Pymes</h2>) : null}
            <BlogDestacados posts={this.state.destacadosPymes} langPage={langPage} idCat={this.state.catPymes} />

            {this.state.destacadosStartup.length !== 0 ? (<h2 className="title-destacados">Destacados Startup</h2>) : null}
            <BlogDestacados posts={this.state.destacadosStartup} langPage={langPage} idCat={this.state.catStartup} />
          </div>
        </div>
      </Layout>
    );
  }
}

export default BlogNew;

export const blogNewQuery = graphql`
  query BlogNewQueryAndCurrentBlogNewQuery( $databaseId: Int!) {
    allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { databaseId: { eq: $databaseId } } }
        }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        databaseId
        title
        content
        excerpt
        slug
        date(formatString: "DD/MM/yyyy")
        seo {
          title
          metaDesc
        }
        author {
          node {
            name
            slug
            avatar {
              url
            }
          }
        }
        categories {
          nodes {
            databaseId
            name
            slug
            ancestors {
              nodes {
                databaseId
                slug
                uri
                name
              }
            }
          }
        }
        featuredImage {
          node {
            altText
            srcSet
            sourceUrl
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    wpCategory(databaseId: { eq: $databaseId }) {
      slug
      name
      uri
      ancestors {
        nodes {
          slug
          name
        }
      }
      seo {
        canonical
        title
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        opengraphPublisher
        opengraphImage {
          altText
          sourceUrl
          title
          mediaDetails {
            height
            width
          }
        }
        twitterDescription
        twitterTitle
        twitterImage {
          altText
          mediaDetails {
            height
            width
          }
          sourceUrl
        }
      }
    }
    wpPage(databaseId: { eq: 6518 }) {
      slug
      date
      uri
      modified
      locale {
        id
      }

      seo {
        canonical
        title
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        opengraphPublisher
        opengraphImage {
          altText
          sourceUrl
          title
          mediaDetails {
            height
            width
          }
        }
        twitterDescription
        twitterTitle
        schema {
          pageType
        }
        twitterImage {
          altText
          mediaDetails {
            height
            width
          }
          sourceUrl
        }
      }
      page_content {
        contentRaw
      }
    }
    allWpCategory(filter: {parentDatabaseId: {eq: $databaseId}}) {
    nodes {
      name
      slug
      id
      databaseId
    }
  }
  }
`;
