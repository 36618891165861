import React from "react";
import { Link } from "gatsby";

const Breadcrumb = ({ props, isChild, isPage }) => {
  if (isChild === "false") {
    return (
            <div className={isPage === "true" ? "breadcrumb page" : "breadcrumb"}>
                <div className="container">
                    <ul>
                        {props.map((item, n) => {
                          console.log(item);
                          const child = item.slug !== null;

                          if (child === true && isPage === "false") {
                            return (
                                    <li key={n}><Link to={item.slug === "blog" ? "../../" + item.slug : "../" + item.slug} className="item">{`${item.title}`}</Link></li>
                            );
                          } else if (child === true && isPage === "true") {
                            return (
                                    <li key={n}><Link to={item.slug} className="item">{`${item.title}`}</Link></li>
                            );
                          } else {
                            return (
                                    <li key={n}><span className="item">{item.title}</span></li>
                            );
                          }
                        })}
                    </ul>
                </div>
            </div>
    );
  } else {
    return (
      null
    );
  }
};

export default Breadcrumb;
